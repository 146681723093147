import loadSkeletonStyles from 'react-loading-skeleton/dist/skeleton.css?inline'
import appStyles from '../app/app.module.css?inline'
import backgroundAnimationStyles from '../components/background-animation/background-animation.module.css?inline'
import buttonStyles from '../components/button/button.module.css?inline'
import cardsStyles from '../components/cards/cards.module.css?inline'
import cvUploadStyles from '../components/cv-upload/cv-upload.module.css?inline'
import errorPageStyles from '../components/error-page/error-page.module.css?inline'
import logoStyles from '../components/logo/logo.module.css?inline'
import modalStyles from '../components/modal/modal.module.css?inline'
import noResultsPageStyles from '../components/no-results-page/no-results-page.module.css?inline'
import progressStyles from '../components/progress/progress.module.css?inline'
import textRevealStyles from '../components/text-reveal/text-reveal.module.css?inline'

import { ENV_TARGET } from '../environments'
import globalStylesAsText from './styles.css?inline'

export const Styles = () => (
	<style>
		{globalStylesAsText}
		{loadSkeletonStyles}
		{ENV_TARGET === 'build' && [
			appStyles,
			backgroundAnimationStyles,
			buttonStyles,
			cardsStyles,
			cvUploadStyles,
			errorPageStyles,
			logoStyles,
			modalStyles,
			noResultsPageStyles,
			progressStyles,
			textRevealStyles,
		]}
	</style>
)
