import { useRef } from 'react'
import { Error } from '../types'

declare global {
	interface Window {
		_etracker: {
			getConfigValue(type: string): string
			sendEvent(event: typeof window.et_UserDefinedEvent): any
		}
		et_eC_Wrapper(data: {
			et_et: string
			et_pagename: string
			et_areas: string
			cc_attributes: {
				etcc_cu: string
				etcc_med_onsite: string
				etcc_cmp_onsite: string
			}
		}): void
		et_UserDefinedEvent: any
	}
}

type PageName = 'Start' | 'Processing' | 'Results' | 'NoResults' | 'JobDetails' | `Error-${Error}`
type TrackingEventObject = 'Consent-Check' | 'Exit-CVM' | 'Matching-Results' | 'Job-Details'
type TrackingEventType = 'Expand' | 'Checkbox' | 'Button' | `Results-${number}`

export const useEtracker = () => {
	const secureCode = useRef<string>()

	const trackPageView = (pageName: PageName) => {
		if (typeof window._etracker !== 'object') {
			console.log('etracker not loaded')
			return
		}

		if (secureCode.current === undefined) {
			secureCode.current = window._etracker.getConfigValue('secureCode')
		}

		if (secureCode.current === undefined) {
			console.log('etracker secureCode not found')
			return
		}

		window.et_eC_Wrapper({
			et_et: secureCode.current,
			et_pagename: `CVMatcher-${pageName}`,
			et_areas: 'CVMatcher',
			cc_attributes: {
				etcc_cu: 'onsite',
				etcc_med_onsite: 'Website',
				etcc_cmp_onsite: 'CVM',
			},
		})
	}

	const trackEvent = (data: [TrackingEventObject, TrackingEventType] | string) => {
		if (typeof window._etracker !== 'object') {
			console.log('etracker not loaded')
			return
		}

		if (typeof data === 'string') {
			window._etracker.sendEvent(new window.et_UserDefinedEvent(data, 'CVMatcher', 'Click', 'Link'))
			return
		}

		window._etracker.sendEvent(new window.et_UserDefinedEvent(data[0], 'CVMatcher', 'Click', data[1]))
	}

	return {
		trackPageView,
		trackEvent,
	}
}
