import { WithChildren, WithClass, WithClick } from '@decadia/shared/types/extend-default-props'
import clsx from 'clsx'
import { HTMLMotionProps, motion } from 'framer-motion'
import { FC, useMemo } from 'react'
import styles from './button.module.css'

type ButtonProps = {
	type?: 'primary' | 'secondary' | 'tertiary'
	size?: 'medium' | 'small'
	dark?: boolean
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isButtonTypeGuard = (props: any): props is HTMLMotionProps<'button'> => {
	return props.href === undefined
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isLinkTypeGuard = (props: any): props is HTMLMotionProps<'a'> => {
	return props.href !== undefined
}

export const Button: FC<
	WithClick<WithClass<WithChildren<ButtonProps & (HTMLMotionProps<'button'> | HTMLMotionProps<'a'>)>>>
> = ({ className, children, type = 'primary', size, dark, ...props }) => {
	const computedClassName = useMemo(
		() => clsx(styles.button, className, 'bold', styles[type], size && styles[size], dark && styles.dark),
		[className, size, type, dark]
	)

	return (
		<>
			{isButtonTypeGuard(props) && (
				<motion.button className={computedClassName} {...props}>
					{children}
				</motion.button>
			)}
			{isLinkTypeGuard(props) && (
				<motion.a className={computedClassName} {...props}>
					{children}
				</motion.a>
			)}
		</>
	)
}
