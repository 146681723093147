import { WithClass } from '@decadia/shared/types/extend-default-props'
import clsx from 'clsx'
import {
	AnimatePresence,
	AnimationProps,
	HTMLMotionProps,
	LayoutGroup,
	MotionStyle,
	TargetAndTransition,
	Variants,
	motion,
	useAnimationControls,
} from 'framer-motion'
import { t } from 'i18next'
import { Dispatch, FC, RefObject, SetStateAction, useEffect, useMemo, useRef, useState } from 'react'
import { Trans } from 'react-i18next'
import LoadingSkeleton from 'react-loading-skeleton'
import { useEtracker } from '../../hooks/use-etracker'
import { useJobUrl } from '../../hooks/use-job-url'
import { Step, useStore } from '../../hooks/use-store'
import { ChevronIcon, ChevronSmallIcon } from '../../icons/chevron'
import { CloseIcon } from '../../icons/close'
import { ExpandIcon } from '../../icons/expand'
import { Result } from '../../types'
import { Button } from '../button/button'
import { TEXT_REVEAL_ANIMATION_DURATION, TextReveal } from '../text-reveal/text-reveal'
import styles from './cards.module.css'

type CardProps = {
	loading?: boolean
	loadingDetails?: boolean
	overline?: string
	title?: string
	insight?: string
	description?: string
	link?: string
	resultNo?: number
	expandedCard?: number
	setExpandedCard?: Dispatch<SetStateAction<number | undefined>>
	parentRef?: RefObject<HTMLDivElement>
	showPreviousMatch?: boolean
	showNextMatch?: boolean
}

const diagonalDuration = 5
const diagonalWrapperVariants: Variants = {
	initial: {
		opacity: 0,
	},
	repeat: {
		opacity: 1,
	},
	exit: {
		opacity: 0,
		filter: 'blur(20px)',

		transition: {
			when: 'beforeChildren',
		},
	},
}

const diagonalItemVariants: Variants = {
	repeat: ({ index, count }) => {
		const delay = diagonalDuration / count

		return {
			filter: ['blur(0px)', 'blur(0px)', 'blur(0px)', 'blur(0px)', 'blur(20px)'],
			opacity: [0, 1, 1, 1, 0],
			x: ['75vw', '-20vw'],
			y: [`-50%`, `50%`],
			transition: {
				delay: TEXT_REVEAL_ANIMATION_DURATION + index * delay,
				duration: diagonalDuration,
				repeat: Infinity,
				repeatDelay: delay * 2,
			},
		}
	},
}

const horizontalDuration = 10
const horizontalWrapperVariants: Variants = {
	initial: {
		opacity: 1,
		x: '100vw',
	},
	repeat: {
		x: ['100vw', '-100%'],
		transition: {
			delay: TEXT_REVEAL_ANIMATION_DURATION,
			duration: horizontalDuration,
			repeat: Infinity,
			ease: 'linear',
		},
	},
	exit: {
		opacity: 0,
		filter: 'blur(20px)',
	},
}

const horizontalItemVariants: Variants = {
	initial: {
		filter: 'blur(20px)',
		opacity: 0,
		transition: {
			duration: 0.3,
		},
	},
	inView: {
		opacity: 1,
		filter: 'blur(0px)',
		transition: {
			duration: 0.3,
		},
	},
}

// const threeCardsDuration = 5
const threeCardsWrapperVariants: Variants = {
	initial: {
		opacity: 0,
	},
	repeat: {
		opacity: 1,
		transition: {
			delay: TEXT_REVEAL_ANIMATION_DURATION + 1,
			duration: 1,
		},
	},
	visible: {
		opacity: 1,
	},
	exit: {
		opacity: 0,
		filter: 'blur(20px)',
	},
}

const randomNumberBetween = (min: number, max: number) => {
	max = max * 10
	min = min * 10

	return Math.floor(Math.random() * (max - min + 1) + min) / 10
}

const threeCardsItemVariants: Variants = {
	repeat: () => ({
		filter: ['blur(5px)', 'blur(0px)', 'blur(10px)', 'blur(0px)', 'blur(5px)'],
		opacity: [
			0.3,
			1,
			randomNumberBetween(0.5, 1),
			1,
			randomNumberBetween(0.5, 1),
			randomNumberBetween(0.5, 1),
			1,
			0.3,
		],
		transition: {
			delay: randomNumberBetween(0.5, 3),
			duration: randomNumberBetween(5, 8),
			repeat: Infinity,
			repeatDelay: randomNumberBetween(1, 3),
		},
	}),
	visible: {
		filter: 'none',
		opacity: 1,
	},
	exit: {
		opacity: 0,
		filter: 'blur(20px)',
	},
}

const Card: FC<CardProps & HTMLMotionProps<'div'>> = ({
	loading,
	loadingDetails,
	overline,
	title,
	insight,
	description,
	resultNo,
	expandedCard,
	setExpandedCard,
	parentRef,
	link,
	showPreviousMatch,
	showNextMatch,
	...rest
}) => {
	const isEmpty = title === undefined
	const isComplete = !isEmpty && !loadingDetails

	const cardRef = useRef<HTMLDivElement>(null)
	const isExpandedCard = useMemo(() => expandedCard !== undefined, [expandedCard])
	const isActiveCard = useMemo(() => expandedCard === resultNo, [expandedCard, resultNo])
	const isInitialExpand = useRef(false)
	const { trackPageView, trackEvent } = useEtracker()

	const handleOnExpand = () => {
		isInitialExpand.current = true
		trackPageView('JobDetails')
		trackEvent(['Job-Details', 'Expand'])

		setExpandedCard?.(resultNo)
	}

	const handleOnClose = () => {
		setExpandedCard?.(undefined)

		trackPageView('Results')

		setTimeout(() => {
			cardRef.current?.parentElement?.scrollTo({ left: 0, behavior: 'instant' })
		}, 1000)
	}

	useEffect(() => {
		if (isActiveCard) {
			setTimeout(
				() => {
					cardRef?.current?.scrollIntoView({
						inline: 'center',
						behavior: 'smooth',
					})
					isInitialExpand.current = false
				},
				isInitialExpand.current ? 1000 : 0
			)
		}
	}, [isActiveCard])

	return (
		<LayoutGroup>
			<motion.div
				layoutId={resultNo !== undefined ? `card-${resultNo}` : undefined}
				// layout
				className={clsx(styles.card)}
				ref={cardRef}
				animate={{
					background:
						isEmpty || loading || loadingDetails
							? 'var(--color--light-grey-transparent)'
							: 'var(--color--white)',
				}}
				style={{
					borderRadius: 10,
				}}
				initial={
					{
						'--controls-opacity': 0,
						'--controls-pointer-events': 'none',
					} as AnimationProps['initial']
				}
				whileInView={
					{
						'--controls-opacity': 1,
						'--controls-pointer-events': 'all',
					} as TargetAndTransition
				}
				viewport={{ amount: 'all' }}
				{...rest}
			>
				<AnimatePresence>
					{isExpandedCard && (
						<motion.button
							key="close-card"
							layout="preserve-aspect"
							className={clsx(styles['close-card'])}
							onClick={handleOnClose}
							style={{
								opacity: 'var(--controls-opacity)',
								pointerEvents: 'var(--controls-pointer-events)' as MotionStyle['pointerEvents'],
							}}
							exit={{
								opacity: 0,
							}}
						>
							<CloseIcon />
						</motion.button>
					)}
					<motion.div
						key="overline"
						layout
						className={styles['overline-wrapper']}
						animate={{
							filter: loading ? 'blur(5px)' : undefined,
						}}
					>
						{overline && (
							<motion.b layout className={styles.overline}>
								{overline}
							</motion.b>
						)}
						{loading && (
							<LoadingSkeleton
								baseColor="var(--overline-background-color)"
								highlightColor="hsla(0, 0%, 100%, 0.2)"
								count={0.6}
							/>
						)}
					</motion.div>
					<motion.h2
						key="headline"
						layout="preserve-aspect"
						className={clsx(styles.title, 'bold')}
						animate={{
							filter: loading ? 'blur(5px)' : undefined,
						}}
					>
						{title}{' '}
						{loading && (
							<LoadingSkeleton
								baseColor="var(--color--blue)"
								highlightColor="hsla(0, 0%, 100%, 0.2)"
								count={1.4}
								width={'80%'}
							/>
						)}
					</motion.h2>
					{isExpandedCard === false && (
						<motion.div
							key="content"
							layoutId={`content-${resultNo}`}
							layout
							className={clsx(
								styles['content-wrapper'],
								description && styles['content-wrapper--filled']
							)}
							animate={{
								filter: loading || loadingDetails ? 'blur(5px)' : undefined,
							}}
							style={{
								lineHeight: loading || loadingDetails ? '200%' : 'inherit',
							}}
						>
							{description && (
								<motion.p layout className={styles.preview}>
									<Trans>{description}</Trans>
								</motion.p>
							)}
							{((loading && window.innerWidth >= 960) || loadingDetails) && (
								<LoadingSkeleton
									containerClassName={styles['preview-skeleton']}
									baseColor="var(--color--blue)"
									highlightColor="hsla(0, 0%, 100%, 0.2)"
									count={3.2}
								/>
							)}
						</motion.div>
					)}

					{isExpandedCard === true && (
						<motion.div
							layout
							key="content"
							layoutId={`content-${resultNo}`}
							className={styles['content-wrapper']}
						>
							<motion.div key="jobDescription" layout className={styles['job-description']}>
								<motion.h3 layout className="medium color--dark-green">
									{t('cards.headline.jobDescription')}
								</motion.h3>
								<motion.p layout>
									<Trans>{description}</Trans>
								</motion.p>
							</motion.div>
							<motion.div
								layout
								className={styles['insight-wrapper']}
								style={{
									borderRadius: 10,
								}}
							>
								<motion.h3 layout className="medium">
									{t('cards.headline.insight')}
								</motion.h3>
								<motion.p layout>
									<Trans>{insight}</Trans>
								</motion.p>
							</motion.div>

							<Button
								layout
								className={styles['link-to-job']}
								size="medium"
								href={link}
								onClick={() => {
									trackEvent(t('cards.button.linkToJob'))
								}}
								rel="noreferrer"
								target="_blank"
							>
								{t('cards.button.linkToJob')}
								<ChevronSmallIcon />
							</Button>
						</motion.div>
					)}

					{isExpandedCard === false && isComplete && (
						<motion.button
							key="expand-card"
							layout="preserve-aspect"
							className={clsx(styles['expand-card'], 'medium')}
							whileHover={'hover'}
							onClick={handleOnExpand}
							initial={{
								opacity: 0,
							}}
							animate={{
								opacity: 1,
							}}
							exit={{
								opacity: 0,
							}}
						>
							{t('cards.expandCard')}
							<ExpandIcon />
						</motion.button>
					)}
					{isExpandedCard === true && resultNo !== undefined && (
						<motion.div
							key="navigation"
							layout="preserve-aspect"
							className={styles.navigation}
							style={{
								opacity: 'var(--controls-opacity)',
								pointerEvents: 'var(--controls-pointer-events)' as MotionStyle['pointerEvents'],
							}}
							exit={{
								opacity: 0,
							}}
						>
							{showPreviousMatch && (
								<Button
									type="secondary"
									onClick={() => setExpandedCard?.(resultNo - 1)}
									className={styles['button-prev']}
								>
									<ChevronIcon /> <span>{t('cards.button.prev')}</span>
								</Button>
							)}

							{showNextMatch && (
								<Button
									type="secondary"
									onClick={() => setExpandedCard?.(resultNo + 1)}
									className={styles['button-next']}
								>
									<span>{t('cards.button.next')}</span>
									<ChevronIcon style={{ rotate: 180 }} />
								</Button>
							)}
						</motion.div>
					)}
				</AnimatePresence>
			</motion.div>
		</LayoutGroup>
	)
}

export const Cards: FC<WithClass> = ({ className }) => {
	const { step, results, jobsCount, links } = useStore(({ step, results, jobsCount, links }) => ({
		step,
		results,
		jobsCount,
		links,
	}))

	const { trackPageView, trackEvent } = useEtracker()

	const threeCardsControls = useAnimationControls()
	const { getJobUrl, getResultLocale } = useJobUrl()

	const [currentVariation, setCurrentVariation] = useState<'horizontal' | 'diagonal' | 'threeCards'>()

	useEffect(() => {
		switch (true) {
			case step <= Step.Processing:
				setCurrentVariation(undefined)
				break

			case step === Step.Preselecting:
				setCurrentVariation('diagonal')
				break

			case step === Step.Evaluating:
				setCurrentVariation('horizontal')
				break

			case step <= Step.Finished: {
				if (step === Step.Finished) {
					results && trackEvent(['Matching-Results', `Results-${results.length}`])
					trackPageView('Results')
				}

				setCurrentVariation('threeCards')
				break
			}

			default:
				setCurrentVariation(undefined)
		}
	}, [step])

	useEffect(() => {
		switch (currentVariation) {
			case 'threeCards': {
				if (step >= Step.Finished) {
					threeCardsControls.start('visible')
					break
				}

				threeCardsControls.start('repeat')
				break
			}
		}
	}, [currentVariation, threeCardsControls, step])

	const [expandedCard, setExpandedCard] = useState<number>()
	const cardsRef = useRef<HTMLDivElement>(null)

	const { current: dummyCards } = useRef(Array.from(Array(10).keys()))

	return (
		<AnimatePresence>
			{currentVariation === 'diagonal' && (
				<motion.div
					key={'diagonal'}
					className={clsx(styles.cards, className, styles[`animation--diagonal`], 'prevent-scroll')}
					initial={'initial'}
					animate={'repeat'}
					variants={diagonalWrapperVariants}
					exit={'exit'}
				>
					{dummyCards.map((_, index, array) => {
						return (
							<Card custom={{ index, count: array.length }} key={index} variants={diagonalItemVariants} />
						)
					})}
				</motion.div>
			)}

			{currentVariation === 'horizontal' && (
				<motion.div
					key={'horizontal'}
					className={clsx(styles.cards, className, styles[`animation--horizontal`], 'prevent-scroll')}
					initial={'initial'}
					animate={'repeat'}
					exit={'exit'}
					variants={horizontalWrapperVariants}
				>
					{dummyCards.map((_, index) => {
						return (
							<Card
								key={index}
								variants={horizontalItemVariants}
								initial={'initial'}
								whileInView={'inView'}
								exit={'exit'}
								viewport={{
									amount: 0.2,
									margin: '0px 0px 0px -60px',
								}}
							/>
						)
					})}
				</motion.div>
			)}

			{currentVariation === 'threeCards' && (
				<motion.div key={'threeCards'} className={clsx(styles.container, className, 'fullsize')}>
					<LayoutGroup>
						<AnimatePresence>
							{/* {step === Step.Selecting && (
								<TextReveal
									key={`headline-${step}`}
									layout="preserve-aspect"
									className={clsx(styles.headline)}
									sentences={(
										t('steps.selecting', { returnObjects: true }) as {
											text: string
											highlighted?: boolean
										}[]
									).map(({ text, highlighted }) => ({
										text,
										className: highlighted ? 'highlight-text' : undefined,
										trailingWhiteSpace: false,
									}))}
									multiLine={false}
									maxSize={54}
								/>
							)} */}

							{[Step.Finished].includes(step) && expandedCard === undefined && (
								<TextReveal
									key={'headline'}
									className={clsx(styles.headline)}
									sentences={[
										{
											text: t('steps.finalizing', {
												count: results ? Math.min(results.length, 3) : undefined,
											}),
										},
									]}
									multiLine={false}
									maxSize={54}
									layout="preserve-aspect"
								/>
							)}

							<motion.div
								layout
								key={'cards'}
								className={clsx(
									styles.cards,
									styles[`animation--three-cards`],
									expandedCard !== undefined && styles['cards--expanded']
								)}
								initial={'initial'}
								animate={threeCardsControls}
								exit={'exit'}
								variants={threeCardsWrapperVariants}
								ref={cardsRef}
								// style={{
								// 	filter:
								// 		expandedCard !== undefined
								// 			? 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) drop-shadow(0px 14px 12px  rgba(0, 0, 0, 0.25))'
								// 			: 'drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.25)) drop-shadow(0px 0px 0px  rgba(0, 0, 0, 0.25))',
								// }}
							>
								<AnimatePresence>
									{(results || Array.from(Array(3).fill({})))
										.slice(0, 3)
										.map((result: Result, index, array) => {
											const finished = step >= Step.Finished
											const loading = result.title === undefined
											if (finished && result.title === undefined) {
												return null
											}

											const link = getJobUrl(result)
											const lang = getResultLocale(result.locale)

											return (
												<Card
													key={`result-${index}`}
													variants={threeCardsItemVariants}
													loading={result.title === undefined}
													loadingDetails={!loading && result.insight === undefined}
													overline={result.title ? t(`cards.results.${index}`) : undefined}
													title={result.title}
													insight={result.insight}
													link={link}
													lang={lang}
													description={result.description_short}
													resultNo={index}
													expandedCard={expandedCard}
													setExpandedCard={setExpandedCard}
													parentRef={cardsRef}
													showPreviousMatch={index > 0 && array.length > 1}
													showNextMatch={index < array.length - 1 && array.length > 1}
												/>
											)
										})}
								</AnimatePresence>
							</motion.div>

							{results && expandedCard === undefined && (
								<>
									<motion.div
										layout
										key={'disclaimer'}
										className={clsx(styles.disclaimer)}
										initial={{
											opacity: 0,
										}}
										animate={{
											opacity: 1,
										}}
										exit={{
											opacity: 0,
										}}
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="51"
											height="57"
											viewBox="0 0 51 57"
											fill="none"
										>
											<path
												d="M25.0744 5.28906C8.36432 5.28906 1 16.1713 1 29.4589C1 36.3358 3.00267 42.5875 7.3153 46.9955C6.10734 52.6432 3.10863 55.6102 3.10863 55.6102C3.10863 55.6102 10.5577 55.928 15.2942 51.9651C18.1128 52.9399 21.3658 53.4803 25.0956 53.4803C41.8799 53.4803 49.1701 42.5239 49.1701 29.4589C49.1489 16.1713 41.8587 5.28906 25.0744 5.28906Z"
												stroke="white"
												strokeWidth="2"
												strokeMiterlimit="10"
											/>
											<path
												d="M34.4673 42.6139V38.9973H28.0655V21.6191H17.5801V25.2357H23.8344V38.9973H17.5801V42.6139H34.4673Z"
												stroke="white"
												strokeWidth="2"
												strokeMiterlimit="10"
											/>
											<path
												d="M25.6859 18.8547C27.2335 18.7016 28.364 17.3228 28.2109 15.7752C28.0578 14.2275 26.6791 13.097 25.1314 13.2501C23.5837 13.4032 22.4532 14.782 22.6063 16.3296C22.7595 17.8773 24.1382 19.0078 25.6859 18.8547Z"
												stroke="white"
												strokeWidth="2"
												strokeMiterlimit="10"
											/>
										</svg>
										<p>{t('cards.disclaimer')}</p>
									</motion.div>
									<motion.div
										layout
										key={'noFit'}
										className={clsx(styles['no-fit'])}
										initial={{
											opacity: 0,
										}}
										animate={{
											opacity: 1,
										}}
										exit={{
											opacity: 0,
										}}
									>
										<h3 className="bold">{t('cards.noFit.headline', { count: results.length })}</h3>
										<Button
											dark
											href={links?.career}
											target="_blank"
											rel="noreferrer"
											onClick={() => {
												trackEvent(t('cards.noFit.buttonLabel'))
											}}
										>
											{t('cards.noFit.buttonLabel')}
										</Button>
									</motion.div>
								</>
							)}
						</AnimatePresence>
					</LayoutGroup>
				</motion.div>
			)}
		</AnimatePresence>
	)
}
