import { SVGMotionProps, Variants, motion } from 'framer-motion'
import { FC } from 'react'

const expandVariants: Variants = {
	hover: (direction: 'up' | 'down') => {
		const multiplier = direction === 'down' ? 1 : -1

		return {
			x: -2 * multiplier,
			y: 2 * multiplier,
			transition: {
				repeat: Infinity,
				repeatType: 'reverse',
				ease: 'easeInOut',
				duration: 0.35,
			},
		}
	},
}

export const ExpandIcon: FC<SVGMotionProps<SVGSVGElement>> = (props) => {
	return (
		<motion.svg
			xmlns="http://www.w3.org/2000/svg"
			width="35"
			height="35"
			viewBox="0 0 35 35"
			fill="none"
			{...props}
		>
			<motion.path
				custom={'down'}
				variants={expandVariants}
				d="M14.7047 22.9884C15.2905 22.4026 15.2905 21.4529 14.7047 20.8671C14.1189 20.2813 13.1692 20.2813 12.5834 20.8671L14.7047 22.9884ZM0.782716 33.2891C0.782716 34.1175 1.45429 34.7891 2.28272 34.7891H15.7827C16.6111 34.7891 17.2827 34.1175 17.2827 33.2891C17.2827 32.4606 16.6111 31.7891 15.7827 31.7891H3.78272V19.7891C3.78272 18.9606 3.11114 18.2891 2.28272 18.2891C1.45429 18.2891 0.782716 18.9606 0.782716 19.7891V33.2891ZM12.5834 20.8671L1.22206 32.2284L3.34338 34.3497L14.7047 22.9884L12.5834 20.8671Z"
				fill="var(--color--dark-green-new)"
			/>
			<motion.path
				custom={'up'}
				variants={expandVariants}
				d="M20.9115 12.5253C20.3257 13.1111 20.3257 14.0608 20.9115 14.6466C21.4973 15.2324 22.447 15.2324 23.0328 14.6466L20.9115 12.5253ZM34.8335 2.22461C34.8335 1.39618 34.1619 0.724611 33.3335 0.724611H19.8335C19.0051 0.724611 18.3335 1.39618 18.3335 2.22461C18.3335 3.05304 19.0051 3.72461 19.8335 3.72461H31.8335V15.7246C31.8335 16.553 32.5051 17.2246 33.3335 17.2246C34.1619 17.2246 34.8335 16.553 34.8335 15.7246V2.22461ZM23.0328 14.6466L34.3942 3.28527L32.2728 1.16395L20.9115 12.5253L23.0328 14.6466Z"
				fill="var(--color--dark-green-new)"
			/>
		</motion.svg>
	)
}
