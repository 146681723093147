import clsx from 'clsx'
import { AnimatePresence, LayoutGroup, MotionConfig, motion } from 'framer-motion'
import { t } from 'i18next'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BackgroundAnimation } from '../components/background-animation/background-animation'
import { Cards } from '../components/cards/cards'
import { CvUpload } from '../components/cv-upload/cv-upload'
import { Debug } from '../components/debug/debug'
import { Logo } from '../components/logo/logo'
import { Modal } from '../components/modal/modal'
import { TModalRefActions } from '../components/modal/modal.types'
import { Progress } from '../components/progress/progress'
import { Steps } from '../components/steps/steps'
import { TEXT_REVEAL_ANIMATION_DURATION } from '../components/text-reveal/text-reveal'
import { ENV_TARGET } from '../environments'
import { useEtracker } from '../hooks/use-etracker'
import { useStatusCheck } from '../hooks/use-status-check'
import { Step, useStore } from '../hooks/use-store'
import '../i18n'
import { Language } from '../i18n'
import { CloseIcon } from '../icons/close'
import { Styles } from '../styles/styles'
import styles from './app.module.css'

const supportsContainerQueries = 'container' in document.documentElement.style

// Conditional Import
if (!supportsContainerQueries) {
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	import('https://cdn.skypack.dev/container-query-polyfill')
}

export type AppProps = {
	autostart?: boolean
	lang?: Language
	dataprotection_de: string
	dataprotection_en: string
	career_de: string
	career_en: string
	jobdetail_de: string
	jobdetail_en: string
	utm?: string
	utm_campaign?: string
	utm_medium?: string
	utm_source?: string
	job_pipeline?: string
}

declare global {
	interface Window {
		RweCVMatch: {
			start: (lang?: Language) => void
		}
	}
}

export const App = (props: AppProps) => {
	const {
		lang = 'en',
		autostart,
		jobdetail_de,
		jobdetail_en,
		utm,
		utm_campaign,
		utm_medium,
		utm_source,
		job_pipeline,
	} = props
	const { i18n } = useTranslation()
	const { step, resetState, setLinks, setUrlParams, setLanguage, language } = useStore(
		({ step, resetState, setLinks, setUrlParams, setLanguage, language }) => ({
			step,
			resetState,
			setLinks,
			setUrlParams,
			setLanguage,
			language,
		})
	)

	const modalRef = useRef<TModalRefActions>()

	useEffect(() => {
		language && i18n.changeLanguage(language)
	}, [i18n, language])

	const [renderApp, setRenderApp] = useState(autostart === true)
	const [contentIsVisible, setContentIsVisible] = useState(false)
	const { trackPageView, trackEvent } = useEtracker()

	useEffect(() => {
		window.RweCVMatch = {
			start(lang) {
				setRenderApp(true)

				if (lang) {
					setLinks({
						dataprotection: props[`dataprotection_${lang}`],
						career: props[`career_${lang}`],
					})
					setLanguage(lang)
				}

				modalRef.current?.open()
			},
		}

		setLinks({
			dataprotection: props[`dataprotection_${lang}`],
			career: props[`career_${lang}`],
			jobdetailDe: jobdetail_de,
			jobdetailEn: jobdetail_en,
		})

		setUrlParams({
			utm,
			utmCampaign: utm_campaign,
			utmMedium: utm_medium,
			utmSource: utm_source,
			jobPipeline: job_pipeline,
		})

		setLanguage(lang)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useStatusCheck()

	const handleOnModalOpen = () => {
		setContentIsVisible(true)
		document.documentElement.style.setProperty('overflow', 'hidden')
	}
	const handleOnModalClose = () => {
		trackEvent(['Exit-CVM', 'Button'])
		setContentIsVisible(false)
		resetState()
		document.documentElement.style.setProperty('overflow', null)
	}

	if (renderApp === false) {
		return null
	}

	return (
		<>
			<Styles />
			<MotionConfig reducedMotion="user">
				<Modal ref={modalRef} onModalOpen={handleOnModalOpen} onModalClose={handleOnModalClose}>
					<AnimatePresence>
						{contentIsVisible && (
							<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} className={styles.container}>
								<Logo className={styles.logo} />
								<motion.button
									initial={{ opacity: 0 }}
									animate={{
										opacity: 1,
										transition: {
											delay: TEXT_REVEAL_ANIMATION_DURATION,
										},
									}}
									className={clsx(styles.close, 'bold')}
									onClick={() => modalRef.current?.close()}
								>
									{t('modal.close')}
									<CloseIcon />
								</motion.button>

								<Steps className={styles.steps} />
								<Cards className={styles.cards} />
								{step < Step.Processing && (
									<motion.div
										initial={{ opacity: 0 }}
										animate={{
											opacity: 1,
											transition: {
												delay: TEXT_REVEAL_ANIMATION_DURATION,
											},
										}}
										exit={{ opacity: 0 }}
										className={styles.bottom}
									>
										<LayoutGroup>
											<motion.div layout="position" className={styles.intro}>
												<h2 className="medium">{t('intro.headline')}</h2>
												<p className="font-size--l">{t('intro.body')}</p>
											</motion.div>
											<CvUpload layout className={styles['cv-upload']} />
										</LayoutGroup>
									</motion.div>
								)}

								<AnimatePresence>
									{step >= Step.Processing && step < Step.Finished && (
										<Progress className={styles.progress} />
									)}
								</AnimatePresence>
							</motion.div>
						)}
					</AnimatePresence>
					<BackgroundAnimation className="visible-on-modal-close" />
					{ENV_TARGET === 'serve' && <Debug />}
				</Modal>
			</MotionConfig>
		</>
	)
}
