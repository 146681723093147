import { ChangeEventHandler, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ENV_API_BASE_URL } from '../environments'
import { SessionResponse } from '../types'
import { useStore } from './use-store'

export const useCvUpload = () => {
	const setSession = useStore((state) => state.setSession)
	const { i18n } = useTranslation()

	const [progress, setProgress] = useState(0)

	const xhrRef = useRef<XMLHttpRequest>()

	const createNewSession = async () => {
		const request = await fetch(
			`${ENV_API_BASE_URL}/upload?mode=automatic&quarantine=yes&document_type=cv&extension=pdf&language=${i18n.language}`,
			{
				method: 'POST',
			}
		)

		if (!request.ok) {
			throw new Error('Failed to fetch presignedURL')
		}

		const { sid, presigned_url: presignedUrl }: SessionResponse = await request.json()

		setSession(sid)

		return { presignedUrl }
	}

	const abortUpload = () => {
		xhrRef.current?.abort()
	}

	const handleCvUpload: ChangeEventHandler<HTMLInputElement> = async (e) => {
		const { files } = e.target satisfies HTMLInputElement

		if (files === null) {
			console.log('files empty')
			return
		}

		const document = files[0]

		const xhr = new XMLHttpRequest()
		xhrRef.current = xhr

		const { presignedUrl } = await createNewSession()

		const resetInput = (resetProgress = true) => {
			resetProgress && setProgress(0)
			xhrRef.current = undefined
			e.target.value = ''
		}

		xhr.upload.onprogress = function (event) {
			const percent = Math.round((100 * event.loaded) / event.total)
			setProgress(percent)
		}

		// handle error
		xhr.upload.onerror = function () {
			console.log(`Error during the upload: ${xhr.status}.`)
			resetInput()
		}

		// upload completed successfully
		xhr.onload = function () {
			console.log('Upload completed successfully.')

			resetInput(false)
		}

		xhr.open('PUT', presignedUrl)
		xhr.setRequestHeader('Content-Type', document.type)
		xhr.send(document)

		xhr.onabort = () => {
			resetInput()
			setSession(undefined)
		}
	}

	return {
		progress,
		handleCvUpload,
		abortUpload,
	}
}
