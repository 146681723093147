import { WithClass } from '@decadia/shared/types/extend-default-props'
import { FC } from 'react'

export const RweLogo: FC<WithClass> = ({ className }) => (
	<svg className={className} xmlns="http://www.w3.org/2000/svg" width="49" height="14" fill="none">
		<g fill="var(--color--white)">
			<path d="M0 1.1.05.83a1.1 1.1 0 0 1 1.1-.8h7.86c.72 0 1.43.07 2.12.29a3.1 3.1 0 0 1 2.22 2.4 7.54 7.54 0 0 1-.08 3.63 3.11 3.11 0 0 1-2.25 2.11c-.23.07-.48.1-.72.16l-.1.02 3.76 5.06H9.9c-.07 0-.1-.02-.14-.07L5.6 7.26c-.11-.18-.15-.36-.04-.55.1-.19.27-.26.48-.26h2.27c.4 0 .8-.1 1.13-.33.37-.25.57-.6.67-1.03.1-.46.09-.93-.04-1.39-.16-.6-.58-.92-1.17-1.03-.2-.03-.4-.05-.61-.05H3.63V13.7H0V1.1ZM18.34.03l1.72 8.4.02.01.03-.09 2.66-7.7a.87.87 0 0 1 .89-.63h1.8c.44 0 .75.22.9.64L29 8.36l.04.1.03-.07 1.4-6.85.29-1.44c.01-.05.02-.08.09-.08h3.63l-.2.8-1.7 7.08-1.23 5.11a.86.86 0 0 1-.88.7h-2.12a.86.86 0 0 1-.86-.61l-2.5-6.87-.4-1.07-.03-.1-.05.1-2.88 7.92a.87.87 0 0 1-.89.62h-2.08c-.47 0-.79-.25-.9-.7L15.34 2.93l-.7-2.91h3.7ZM46.69 5.42v2.62H39.7c-.08 0-.13.04-.13.13V10.97h8.57v2.73H37.26a1.15 1.15 0 0 1-1.16-1.13V1.15c0-.56.33-.98.87-1.1l.3-.03h10.78V2.55c0 .1-.02.12-.12.12h-8.2c-.16 0-.15-.02-.15.14v2.5c0 .09.02.11.12.11H46.7Z" />
		</g>
	</svg>
)
