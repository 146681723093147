import i18n, { ResourceLanguage } from 'i18next'
import { initReactI18next } from 'react-i18next'
import { locales } from './locales'

const resources = (Object.keys(locales) as Language[]).reduce(
	(transformedLocales: Locale, locale: Language): Locale => {
		transformedLocales[locale] = {
			translation: locales[locale],
		}

		return transformedLocales
	},
	{} as Locale
)

const languages = ['de', 'en'] as const

export type Language = (typeof languages)[number]

export type Locale = Record<Language, ResourceLanguage>

i18n.use(initReactI18next).init({
	resources,
	lng: 'en',
	interpolation: {
		escapeValue: false, // react already safes from xss
	},
	fallbackNS: 'translation',
	supportedLngs: languages,
	react: {
		transEmptyNodeValue: '️️⚠️ MISSING TRANSLATION ⚠️', // what to return for empty Trans
		transSupportBasicHtmlNodes: true, // allow <br/> and simple html elements in translations
		transKeepBasicHtmlNodesFor: [
			'span',
			'br',
			'strong',
			'i',
			'li',
			'ul',
			'ol',
			'p',
			'b',
			'<',
			'table',
			'tbody',
			'th',
			'td',
			'tr',
		], // don't convert to <1></1> if simple react elements
	},
})

export default i18n
