import { useIntervalEffect } from '@react-hookz/web'
import { useEffect, useRef, useState } from 'react'
import { ENV_API_BASE_URL } from '../environments'
import { JobsCountResponse, Status, StatusCheckResponse } from '../types'
import { Step, statusToStepEquivalent, stepDuration, useStore } from './use-store'

const CHECK_INTERVAL = 500
const SECONDS_UNTIL_TIMEOUT = 120

export const useStatusCheck = () => {
	const { session, setStep, step, setResults, jobsCount, setJobsCount, setError } = useStore(
		({ session, setStep, step, setResults, jobsCount, setJobsCount, setError }) => ({
			session,
			setStep,
			step,
			setResults,
			jobsCount,
			setJobsCount,
			setError,
		})
	)

	const [runCheck, setRunCheck] = useState(false)
	const fetchInProgress = useRef(false)
	const startTime = useRef<number>()
	const stepTime = useRef<number>()
	const previousStatus = useRef<Status>()

	const resetCheck = () => {
		setRunCheck(false)
		fetchInProgress.current = false
		startTime.current = undefined
		stepTime.current = undefined
		previousStatus.current = undefined
	}

	const checkStatus = async () => {
		if (fetchInProgress.current === true) {
			return
		}

		if (startTime.current === undefined) {
			startTime.current = performance.now()
		}

		const totalTimeElapsedInSeconds = Math.floor((performance.now() - startTime.current) / 1000)

		if (totalTimeElapsedInSeconds > SECONDS_UNTIL_TIMEOUT) {
			setError('timeout')
			setStep(Step.Error)
			return
		}

		fetchInProgress.current = true

		if (previousStatus.current !== 'SUCCESSFUL' && previousStatus.current !== 'FAILED') {
			const request = await fetch(`${ENV_API_BASE_URL}/status_check?sid=${session}`)

			if (!request.status || request.status >= 500) {
				setError('other')
				setStep(Step.Error)
				return
			}

			const { error, result, status }: StatusCheckResponse = await request.json()

			if (status === 'FAILED') {
				setError(error || 'other')
				setStep(Step.Error)
				return
			}

			if (previousStatus.current !== status) {
				previousStatus.current = status
			}

			// if (status === 'FINALIZATION') {
			// 	if (result?.length === 0) {
			// 		setStep(Step.NoResults)
			// 		return
			// 	}

			// 	setStep(Step.Finalizing)
			// 	setResults(result)
			// 	return
			// }

			if (status === 'SUCCESSFUL') {
				if (result?.length === 0) {
					setStep(Step.NoResults)
					return
				}

				setResults(result)
				setStep(Step.Finished)
				return
			}
		}

		if (step === Step.Initial) {
			setStep(Step.Processing)
		}

		if (stepTime.current === undefined) {
			stepTime.current = performance.now()
		}

		const stepTimeElapsedInSeconds = Math.floor((performance.now() - stepTime.current) / 1000)

		if (stepDuration[step] !== undefined && stepTimeElapsedInSeconds >= stepDuration[step]) {
			if (step < Step.Selecting && statusToStepEquivalent[previousStatus.current] <= Step.Selecting) {
				stepTime.current = undefined
				setStep(step + 1)
			}
		}

		fetchInProgress.current = false
	}

	useIntervalEffect(checkStatus, runCheck ? CHECK_INTERVAL : undefined)

	useEffect(() => {
		if ([Step.Finished, Step.NoResults, Step.Error].includes(step)) {
			resetCheck()
			return
		}

		setRunCheck(session !== undefined)
	}, [session, step])

	useEffect(() => {
		const getJobsCount = async () => {
			const request = await fetch(`${ENV_API_BASE_URL}/jobs_total`)
			const { total_jobs }: JobsCountResponse = await request.json()

			total_jobs && setJobsCount(total_jobs)
		}

		if (jobsCount === undefined) {
			getJobsCount()
		}
	}, [jobsCount, setJobsCount])
}
