import { WithClass } from '@decadia/shared/types/extend-default-props'
import clsx from 'clsx'
import {
	AnimatePresence,
	HTMLMotionProps,
	LayoutGroup,
	MotionStyle,
	motion,
	useAnimate,
	useMotionValue,
} from 'framer-motion'
import { t } from 'i18next'
import { ChangeEventHandler, DragEventHandler, FC, useEffect, useRef, useState } from 'react'
import { Trans } from 'react-i18next'
import { useCvUpload } from '../../hooks/use-cv-upload'
import { useEtracker } from '../../hooks/use-etracker'
import { useStore } from '../../hooks/use-store'
import { Button } from '../button/button'
import styles from './cv-upload.module.css'

const ALLOWED_MIME_TYPES = ['application/pdf']

export const CvUpload: FC<WithClass<HTMLMotionProps<'div'>>> = ({ className, ...rest }) => {
	const { consent, setConsentTrue, links } = useStore(({ consent, setConsentTrue, links }) => ({
		consent,
		setConsentTrue,
		links,
	}))

	const { progress, handleCvUpload, abortUpload } = useCvUpload()
	const { trackEvent } = useEtracker()

	const [isDragOver, setIsDragOver] = useState(false)
	const [fileStatus, setFileStatus] = useState<'empty' | 'invalidFileFormat' | 'multipleFiles' | 'valid'>()
	const [, animate] = useAnimate()

	const progressMotionValue = useMotionValue(0)

	useEffect(() => {
		animate(progressMotionValue, Math.min(progress / 100, 1))
	}, [animate, progress, progressMotionValue])

	const inputRef = useRef<HTMLInputElement>(null)

	const checkFiles = (files: FileList | DataTransferItemList | null): typeof fileStatus => {
		const getFileInfo = (): typeof fileStatus => {
			if (files === null || files.length === 0) {
				return 'empty'
			}

			if (files.length > 1) {
				return 'multipleFiles'
			}

			const file = files[0]

			if (ALLOWED_MIME_TYPES.includes(file.type) === false) {
				return 'invalidFileFormat'
			}

			return 'valid'
		}

		const fileInfo = getFileInfo()

		setFileStatus(fileInfo)
		return fileInfo
	}

	const handleOnDragOver: DragEventHandler = (e) => {
		e.stopPropagation()
		e.preventDefault()

		if (isDragOver === false) {
			setIsDragOver(true)
		}
	}

	const handleOnDragEnter: DragEventHandler = (e) => {
		e.stopPropagation()
		e.preventDefault()

		checkFiles(e.dataTransfer.items)

		setIsDragOver(true)
	}

	const handleOnDragLeave: DragEventHandler = (e) => {
		e.stopPropagation()
		e.preventDefault()

		setIsDragOver(false)
	}

	const handleOnDrop: DragEventHandler = (e) => {
		e.preventDefault()
		setIsDragOver(false)

		if (inputRef.current) {
			inputRef.current.files = e.dataTransfer.files

			const event = new Event('change', { bubbles: true })

			inputRef.current.dispatchEvent(event)
		}
	}

	const handleOnInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
		e.preventDefault()

		if (checkFiles(e.target.files) === 'valid') {
			handleCvUpload(e)
			return
		}

		if (inputRef.current) {
			inputRef.current.value = ''
		}
	}
	return (
		<motion.div {...rest} className={clsx(styles.container, className)}>
			<LayoutGroup>
				<motion.h2
					layout="position"
					className="medium"
					initial={{ opacity: 0 }}
					animate={{ opacity: consent === true ? 1 : 0 }}
				>
					{t('cvUpload.headline')}
				</motion.h2>
				<motion.div
					layout
					style={{ borderRadius: 10 }}
					className={clsx(styles.box, isDragOver && styles['drag-over'], progress > 0 && styles.uploading)}
					onDragEnter={handleOnDragEnter}
					onDragLeave={handleOnDragLeave}
					onDragOver={handleOnDragOver}
					onDrop={handleOnDrop}
				>
					<AnimatePresence mode="wait">
						{consent !== true && (
							<motion.div exit={{ opacity: 0 }} key="consent" layout>
								<motion.p>
									<b>{t('cvUpload.consent.headline')}</b>
								</motion.p>

								<motion.p layout>
									<Trans
										i18nKey={'cvUpload.consent.dateProtection'}
										components={{
											dataProtectionLink: (
												<a href={links?.dataprotection} target="_blank" rel="noreferrer">
													{' '}
												</a>
											),
										}}
									/>
								</motion.p>

								<motion.label layout className={styles.consent}>
									<input
										type="checkbox"
										defaultChecked={consent}
										onChange={() =>
											setTimeout(() => {
												trackEvent(['Consent-Check', 'Checkbox'])
												setConsentTrue()
											}, 300)
										}
									/>
									<b>{t('cvUpload.consent.checkboxLabel')}</b>
								</motion.label>
							</motion.div>
						)}

						{consent === true && progress === 0 && (
							<motion.div
								layout
								key="upload-cv"
								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								exit={{ opacity: 0 }}
								className={styles['upload-cv']}
							>
								<motion.label
									layout
									htmlFor="file-upload"
									style={{ borderRadius: 4 }}
									className={styles['drop-zone']}
								>
									<b>{t('cvUpload.dropZone.empty')}</b>
								</motion.label>

								<motion.b layout className={styles.separator}>
									{t('cvUpload.dropZone.separator')}
								</motion.b>

								<Button layout onClick={() => inputRef.current?.click()} className={styles.button}>
									{t('cvUpload.dropZone.buttonLabel')}
								</Button>

								{fileStatus && ['valid', 'empty'].includes(fileStatus) === false && (
									<motion.span layout style={{ borderRadius: 4 }} className={styles.error}>
										{t(`cvUpload.dropZone.error.${fileStatus}`)}
									</motion.span>
								)}
							</motion.div>
						)}

						{consent === true && progress > 0 && (
							<motion.div
								layout
								key="progress"
								className={styles['progress-wrapper']}
								initial={{ opacity: 0 }}
								animate={{
									opacity: 1,
									transition: {
										delay: 0.5,
									},
								}}
							>
								<motion.span
									layout
									className={styles.progress}
									style={
										{
											'--progress': progressMotionValue,
										} as MotionStyle
									}
								>
									<b data-text={t('cvUpload.inProgress')}>{t('cvUpload.inProgress')}</b>

									<Button
										animate={{
											opacity: progress >= 100 ? 0 : 1,
										}}
										disabled={progress >= 100}
										onClick={() => abortUpload?.()}
									>
										{t('cvUpload.abort')}
									</Button>
								</motion.span>
								<motion.span
									layout
									className={styles['progress-bar']}
									style={{ scaleX: progressMotionValue, originX: 0 }}
								/>
							</motion.div>
						)}
					</AnimatePresence>
				</motion.div>
				<input
					tabIndex={-1}
					type="file"
					id="file-upload"
					ref={inputRef}
					className={styles.input}
					onChange={handleOnInputChange}
					accept={ALLOWED_MIME_TYPES.join(',')}
				/>
			</LayoutGroup>
		</motion.div>
	)
}
