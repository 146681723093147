import { WithClass } from '@decadia/shared/types/extend-default-props'
import clsx from 'clsx'
import { AnimationProps, motion, useInView } from 'framer-motion'
import { FC, useRef } from 'react'
import styles from './background-animation.module.css'

const defaultTransitionProps: AnimationProps['transition'] = {
	repeat: Infinity,
	repeatType: 'mirror',
	ease: 'easeInOut',
}

export const BackgroundAnimation: FC<WithClass> = ({ className }) => {
	const ref = useRef<HTMLDivElement>(null)
	const isInView = useInView(ref)

	return (
		<div ref={ref} className={clsx(styles.container, className)}>
			<motion.svg
				className={styles.svg}
				viewBox="0 0 1512 983"
				preserveAspectRatio="none"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				initial={{
					x: 0,
					y: 0,
					rotate: 0,
				}}
				animate={
					isInView
						? {
								x: 50,
								y: [30, -20, 10],
								rotate: [25, -10, 10],
							}
						: undefined
				}
				transition={
					isInView
						? {
								...defaultTransitionProps,
								duration: 20,
							}
						: undefined
				}
			>
				<motion.path
					d="M408.474 1140.77C331.784 1256.3 120.945 1061.14 34.7982 931.275C-66.3509 707.104 280.412 546.871 357.452 457.231C434.492 367.591 682.72 348.314 813.513 432.808C944.305 517.302 883.218 706.72 638.563 698.828C393.908 690.937 485.163 1025.24 408.474 1140.77Z"
					fill="var(--color--light-green)"
					initial={{
						x: 0,
						y: 0,
						rotate: 0,
					}}
					animate={
						isInView
							? {
									x: 800,
									y: [200, 400, -300],
									rotate: [15, 4, -20],
								}
							: undefined
					}
					transition={
						isInView
							? {
									...defaultTransitionProps,
									duration: 8,
								}
							: undefined
					}
				/>
				<motion.path
					d="M335.025 591.128C106.742 675.355 28.1812 310.146 49.7504 102.59C151.838 -224.544 796.289 -183.923 997.33 -237.792C1198.37 -291.66 1557.52 -151.526 1645.42 32.4667C1733.31 216.459 1447.19 396.897 1122 227.484C796.81 58.0714 563.307 506.9 335.025 591.128Z"
					fill="var(--color--blue)"
					initial={{
						x: 0,
						y: 0,
						rotate: 0,
					}}
					animate={
						isInView
							? {
									x: 200,
									y: [50, -20, -30],
									rotate: [-8, 15],
								}
							: undefined
					}
					transition={
						isInView
							? {
									...defaultTransitionProps,
									duration: 4,
								}
							: undefined
					}
				/>
				<motion.path
					d="M-149.655 497.306C-264.765 501.37 -213.736 312.306 -159.146 237.084C-79.0239 126.68 -22.6781 88.6755 -0.130997 198.458C22.4161 308.239 110.231 298.074 126.845 426.153C143.458 554.232 40.2164 552.199 -7.25113 531.869C-54.7187 511.539 -34.5452 493.242 -149.655 497.306Z"
					fill="var(--color--orange)"
					initial={{
						x: 0,
						y: 0,
						rotate: 0,
					}}
					animate={
						isInView
							? {
									x: 1500,
									y: [-150, 300, -100],
									rotate: [22, -15, 40],
								}
							: undefined
					}
					transition={
						isInView
							? {
									...defaultTransitionProps,
									duration: 16,
								}
							: undefined
					}
				/>
			</motion.svg>
		</div>
	)
}
