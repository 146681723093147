import { SVGMotionProps, motion } from 'framer-motion'
import { FC } from 'react'

export const ChevronIcon: FC<SVGMotionProps<SVGSVGElement>> = (props) => (
	<motion.svg xmlns="http://www.w3.org/2000/svg" width="15" height="23" viewBox="0 0 15 23" fill="none" {...props}>
		<path d="M13 1.28906L3 10.9665L13 21.2891" stroke="var(--icon-color, var(--color--blue))" strokeWidth="3" />
	</motion.svg>
)

export const ChevronSmallIcon: FC<SVGMotionProps<SVGSVGElement>> = (props) => (
	<motion.svg xmlns="http://www.w3.org/2000/svg" width="9" height="14" viewBox="0 0 9 14" fill="none" {...props}>
		<path d="M1 13L7 7.19355L0.999999 1" stroke="var(--icon-color, var(--color--blue))" strokeWidth="2" />
	</motion.svg>
)
