export const addQueryParameterToUrl = ({
	link,
	queryParams,
}: {
	link?: string
	queryParams: [string, string | undefined][]
}) => {
	if (link === undefined) {
		return ''
	}

	const { origin, pathname, searchParams } = new URL(link)

	const params = new URLSearchParams(searchParams.toString())

	queryParams.forEach(([key, value]) => {
		if (value) {
			params.set(key, value)
		}
	})

	if (params.size === 0) {
		return link
	}

	const queryString = params.toString()

	return `${origin}${pathname}?${queryString}`
}
