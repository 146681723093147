import { WithChildren, WithClass } from '@decadia/shared/types/extend-default-props'
import clsx from 'clsx'
import { AnimationPlaybackControls, AnimationProps, animate, motion, useAnimate, usePresence } from 'framer-motion'
import { FC, useEffect, useRef } from 'react'
import { Step, stepDuration, useStore } from '../../hooks/use-store'
import styles from './progress.module.css'

export const Progress: FC<WithClass> = ({ className }) => {
	const { step, progress } = useStore(({ step, progress }) => ({
		step,
		progress,
	}))
	const [isPresent, safeToRemove] = usePresence()
	const [scope, animate] = useAnimate()

	const handleOnComplete = async () => {
		if (isPresent) {
			return
		}

		await animate(scope.current, { opacity: 0 })

		safeToRemove()
	}

	return (
		<motion.div
			className={clsx(styles.container, className)}
			initial={{
				opacity: 0,
			}}
			animate={{
				opacity: 1,
			}}
			ref={scope}
		>
			<span className={styles.spinner} />
			<span className={clsx(styles.content, 'bold')}>
				<div className={styles.dots}>
					{Array.from(Array(Step.Finished).keys()).map((stepNo) => {
						return (
							<motion.span
								key={stepNo}
								initial={
									{
										'--scale': 0,
									} as AnimationProps['initial']
								}
								animate={
									{
										'--scale': stepNo < step ? 1 : 0,
									} as AnimationProps['animate']
								}
							/>
						)
					})}
				</div>
				<Counter value={progress || 0} duration={stepDuration[step] || 0} onComplete={handleOnComplete}>
					%
				</Counter>
			</span>
		</motion.div>
	)
}

const Counter: FC<WithClass<WithChildren<{ value: number; duration?: number; onComplete?: () => void }>>> = ({
	className,
	children,
	value,
	duration,
	onComplete,
}) => {
	const ref = useRef<HTMLSpanElement>(null)
	const animationRef = useRef<AnimationPlaybackControls>()

	useEffect(() => {
		if (ref.current) {
			let currentValue = parseInt(ref.current.textContent || '')
			currentValue = Number.isNaN(currentValue) ? 0 : currentValue

			if (animationRef.current) {
				animationRef.current.cancel()
			}

			animationRef.current = animate(currentValue, value, {
				onUpdate: (latest) => {
					if (ref.current) {
						ref.current.textContent = `${latest.toFixed(0)}${children}`
					}
				},
				onComplete,
				duration,
			})
		}
	}, [children, duration, value, onComplete])

	return <span className={className} ref={ref} />
}
