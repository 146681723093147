import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'
import { TEXT_REVEAL_ANIMATION_DURATION } from '../components/text-reveal/text-reveal'
import { Language } from '../i18n'
import { Error, Result, Status } from '../types'

export enum Step {
	'Initial',
	'Processing',
	'Preselecting',
	'Evaluating',
	'Selecting',
	// 'Finalizing',
	'Finished',
	'NoResults',
	'Error',
}

export const statusToStepEquivalent: Record<Status, Step> = {
	INITIAL: Step.Initial,
	UPLOAD: Step.Processing,
	SCAN: Step.Processing,
	OPEN: Step.Processing,
	PREPROCESS: Step.Preselecting,
	PRESELECTION: Step.Evaluating,
	EVALUATION: Step.Selecting,
	// FINALIZATION: Step.Finalizing,
	SUCCESSFUL: Step.Finished,
	FAILED: Step.Error,
}

export const stepDuration: Record<number, number> = {
	[Step.Processing]: TEXT_REVEAL_ANIMATION_DURATION + 2,
	[Step.Preselecting]: TEXT_REVEAL_ANIMATION_DURATION + 5,
	[Step.Evaluating]: TEXT_REVEAL_ANIMATION_DURATION + 5,
	[Step.Selecting]: TEXT_REVEAL_ANIMATION_DURATION + 4,
	[Step.Finished]: 0.5,
	// [Step.Processing]: TEXT_REVEAL_ANIMATION_DURATION,
	// [Step.Preselecting]: TEXT_REVEAL_ANIMATION_DURATION,
	// [Step.Evaluating]: TEXT_REVEAL_ANIMATION_DURATION,
	// [Step.Selecting]: TEXT_REVEAL_ANIMATION_DURATION,
	// [Step.Finalizing]: TEXT_REVEAL_ANIMATION_DURATION,
}

const progress: Record<Step, number> = {
	[Step.Initial]: 0,
	[Step.Processing]: 10,
	[Step.Preselecting]: 45,
	[Step.Evaluating]: 70,
	[Step.Selecting]: 95,
	// [Step.Finalizing]: 90,
	[Step.Finished]: 100,
	[Step.NoResults]: -1,
	[Step.Error]: -1,
}

export type Store = StoreState & StoreActions

const initialState: StoreState = {
	step: 0,
	language: 'en',
	session: undefined,
	progress: undefined,
	results: undefined,
	error: undefined,
}

export const useStore = create<Store>()(
	immer((set) => ({
		...initialState,
		setSession: (data) =>
			set((state) => {
				state.session = data
			}),
		setStep: (data) =>
			set((state) => {
				state.step = data
				state.progress = progress[data]

				if (data === Step.Error) {
					state.session = undefined
				}
			}),
		setResults: (data) => {
			set((state) => {
				state.results = data
			})
		},
		setConsentTrue: () => {
			set((state) => {
				state.consent = true
			})
		},
		setJobsCount: (data) => {
			set((state) => {
				state.jobsCount = data
			})
		},
		resetState: () =>
			set((state) => {
				return { ...initialState, consent: state.consent }
			}),
		setError: (data) =>
			set((state) => {
				state.error = data
			}),
		setLinks: (data) =>
			set((state) => {
				data = Object.entries(data || {}).reduce((editedLinks, [key, value]) => {
					try {
						new URL(value)
					} catch (error) {
						const { origin, pathname } = window.location
						const maybeWithPathname = value.startsWith('/') ? '' : pathname
						value = [origin, maybeWithPathname, value].join('')
					}

					return {
						...editedLinks,
						[key]: value,
					}
				}, {})

				state.links = {
					...state.links,
					...data,
				}
			}),
		setUrlParams: (data) =>
			set((state) => {
				state.urlParams = data
			}),
		setLanguage: (data) =>
			set((state) => {
				state.language = data
			}),
	}))
)

export type StoreState = {
	consent?: boolean
	session?: string
	step: Step
	progress?: number
	results?: Result[]
	jobsCount?: number
	error?: Error
	language: Language
	links?: {
		dataprotection?: string
		career?: string
		jobdetailDe?: string
		jobdetailEn?: string
	}
	urlParams?: {
		utm?: string
		utmCampaign?: string
		utmMedium?: string
		utmSource?: string
		jobPipeline?: string
	}
}

export type StoreActions = {
	setSession: (data: StoreState['session']) => void
	setStep: (data: StoreState['step']) => void
	setResults: (data: Result[] | undefined) => void
	setConsentTrue: () => void
	setJobsCount: (data: StoreState['jobsCount']) => void
	setError: (data: StoreState['error']) => void
	setLinks: (data: StoreState['links']) => void
	setUrlParams: (data: StoreState['urlParams']) => void
	setLanguage: (data: StoreState['language']) => void
	resetState: () => void
}
