import { SVGMotionProps, motion } from 'framer-motion'
import { FC } from 'react'

export const NoResultsIllustration: FC<SVGMotionProps<SVGSVGElement>> = (props) => (
	<motion.svg
		{...props}
		xmlns="http://www.w3.org/2000/svg"
		width="401"
		height="311"
		viewBox="0 0 401 311"
		fill="none"
	>
		<path
			fill="#00A19F"
			d="M373.8 32h-227c-14.8 0-26.7 12-26.7 26.7v224.1c0 14.8 12 26.8 26.7 26.8h227c14.8 0 26.7-12 26.7-26.8V58.7c0-14.8-12-26.7-26.7-26.7Z"
		/>
		<path
			fill="#E8E8E4"
			d="M271.2 146.5a4 4 0 0 1 .7-6l-3.3-13.4 6.2-4.1 2.6 18.9a4 4 0 0 1-.7 4.8 3.8 3.8 0 0 1-5.5-.2Z"
		/>
		<path
			fill="#3ED8C3"
			d="m277.7 133.2-8.2.8-12-42.7c-.1 0-3.7-12-7.3-14-1.4-.6-1.9-1-1.9-1.5 0-.4.6-.6 1.4-1 .7-.2 1.4-.6 2.1-1 1.7-1.4 1-2.6 0-4.4l-.7-1c-.5-1.1.1-5.7 2-13.8a11.6 11.6 0 0 1 23 1.9l1.5 24.7v52Z"
		/>
		<path fill="#E8E8E4" d="M245.6 285H240l-2.6-22.4h8.4l-.2 22.4Z" />
		<path
			fill="#1D4477"
			d="M227.4 294a2.5 2.5 0 0 0 2.6 2.6l11.3.1 2-4 .7 4h4.2l-1-14.3h-1.5l-6-.5-2-.1v4l-9.1 6.2a2.5 2.5 0 0 0-1.2 2Z"
		/>
		<path fill="#E8E8E4" d="m305.4 280.9-5.4 1.7-9.2-20.6 8-2.5 6.6 21.4Z" />
		<path
			fill="#1D4477"
			d="M290.8 295a2.5 2.5 0 0 0 3.2 1.7l10.8-3.3.6-4.5 2 3.7 4-1.3-5.3-13.4-1.5.4-5.9 1.4-1.9.5 1.2 3.9-6.8 8.6a2.5 2.5 0 0 0-.4 2.3ZM234.2 96.6 223.4 128l-.4 2.5c-2.7 2.6.7 3.5-.6 4.1-1.2.7-2.1 2.6-.4 3 1.8.3 1 125 8.4 129.7 7.4 4.8 24.4 10.1 23 3.1-1.3-7-.8-93.2-.8-93.2s36.2 91.8 38.7 91.8c2.4 0 20.1-6.6 19.3-9.8-.8-3.1-33-82.9-30.7-86 2.1-3 3-4.3.8-4.5-2.1-.2-2.8 1-2.9-2l-.5-10.3s-1.5-29-12.4-45l-1-9.6-29.7-5.3Z"
		/>
		<path
			fill="#3ED8C3"
			d="m261.3 36.5-14.4 2-.8 10.4L239 60s-15.8 11.1-4.8 28.6l-.7 9.7 35.5 14.4 3.4-62.4-11.1-13.8Z"
		/>
		<path
			fill="#E8E8E4"
			d="M237.2 150a4 4 0 0 1 2.3-5.4l.6-13.9 7.2-2.1-3 18.8a4 4 0 0 1-2 4.4 3.8 3.8 0 0 1-5-1.7h-.1Z"
		/>
		<path
			fill="#3ED8C3"
			d="m247.2 139.2-8-1.6.4-44.3c0-.2 0-12.7-3-15.5-1-1-1.4-1.6-1.3-2 .2-.4.8-.5 1.6-.5s1.6-.2 2.3-.5c2-.8 1.7-2.2 1.2-4.2l-.3-1.1c-.2-1.2 1.7-5.5 5.8-12.6a11.6 11.6 0 0 1 21.5 8.3l-5.5 24.1-14.7 49.9Z"
		/>
		<path fill="#E8E8E4" d="M252.7 36a16 16 0 1 0 0-32 16 16 0 0 0 0 32Z" />
		<path
			fill="#1D4477"
			d="M274 42.3c2.1 3 3.5 6.6 4.2 10.3a10.7 10.7 0 0 1-5.9 1.7l-.2-.8c-.6-2.1-1.5-4.1-2.6-6 .1 2-.1 4-.8 6-1.1-.6-2.3-.9-3.5-1-1 0-1.9.3-2.8.7l-2.6-8.7c0 5.6-8.1 10.4-17 9.7-.3-.2 3-9.3 2.6-9.5l-2-1.5c2.8-4.5 6-9.3 5.4-10 0 1-3.1 5.2-6.6 9.2-1.9-1.4 9-24.3 3.6-26.2 0 .8-1.2.8-2.7.7-.4-1.4-1-2.7-1.7-3.8.1 1.2 0 2.3-.4 3.5-1.4 0-2.9.1-4.3.4l-1-1.4v1.7c-1 0-2 .4-2.9 1-1-.5.8-2.3.1-3.3-1.8-2.4.9-5.9 2.6-8.4a12.7 12.7 0 0 1 16.3-3.8c.2-.8 1.4-1.4 3-1.7.7-.2 1.4-.2 2-.2 6.8.1 16.3 5.7 16.6 12.5l.2 2.2c.5 7.6-.1 14.6 5 20.2 2 2 2 11.5 1.2 16.4-1.8-2-3.2-9.6-5.9-9.9ZM194.8 156a1.2 1.2 0 0 1-1.2-1.3v-41a1.2 1.2 0 0 1 2.4 0v41a1.2 1.2 0 0 1-1.2 1.2Z"
		/>
		<rect width="39.4" height="9.9" x="1" y="5.9" fill="#E8E8E4" rx="3.9" />
		<rect width="179" height="12.1" x="1" y="24.1" fill="#1D4477" rx="3.9" />
		<rect width="39.4" height="9.9" x="47.3" y="5.9" fill="#E8E8E4" rx="3.9" />
		<rect width="39.4" height="9.9" x="1" y="49.9" fill="#00A19F" rx="3.9" />
		<rect width="40.9" height="17.5" x="136.8" y="49.9" fill="#3ED8C3" rx="3.9" />
		<path stroke="#E8E8E4" strokeLinecap="round" d="M1.4 93.1H175" />
		<rect width="39.4" height="9.9" x="1" y="105.6" fill="#E8E8E4" rx="3.9" />
		<rect width="179" height="12.1" x="1" y="123.8" fill="#1D4477" rx="3.9" />
		<rect width="39.4" height="9.9" x="47.3" y="105.6" fill="#E8E8E4" rx="3.9" />
		<rect width="39.4" height="9.9" x="1" y="149.6" fill="#00A19F" rx="3.9" />
		<rect width="40.9" height="17.5" x="136.8" y="149.6" fill="#3ED8C3" rx="3.9" />
		<path stroke="#E8E8E4" strokeLinecap="round" d="M1.4 192.8H175" />
		<rect width="39.4" height="9.9" x="1" y="207.4" fill="#E8E8E4" rx="3.9" />
		<rect width="179" height="12.1" x="1" y="225.7" fill="#1D4477" rx="3.9" />
		<rect width="39.4" height="9.9" x="47.3" y="207.4" fill="#E8E8E4" rx="3.9" />
		<rect width="39.4" height="9.9" x="1" y="251.4" fill="#00A19F" rx="3.9" />
		<rect width="40.9" height="17.5" x="136.8" y="251.4" fill="#3ED8C3" rx="3.9" />
		<path stroke="#E8E8E4" strokeLinecap="round" strokeWidth="3" d="M1.4 295.6h357.8" />
	</motion.svg>
)
