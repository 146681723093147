import r2wc from '@r2wc/react-to-web-component'
import { App } from './app/app'

customElements.define(
	'rwe-cv-matcher',
	r2wc(App, {
		props: {
			lang: 'string',
			autostart: 'boolean',
			dataprotection_de: 'string',
			dataprotection_en: 'string',
			career_de: 'string',
			career_en: 'string',
			jobdetail_de: 'string',
			jobdetail_en: 'string',
			utm: 'string',
			utm_campaign: 'string',
			utm_medium: 'string',
			utm_source: 'string',
			job_pipeline: 'string',
		},
		shadow: 'closed',
	})
)
