import { StoreState } from '../hooks/use-store'
import { addQueryParameterToUrl } from './add-query-string-to-url'

export const addUrlParametersToUrl = ({ link, urlParams }: { link?: string; urlParams: StoreState['urlParams'] }) => {
	return addQueryParameterToUrl({
		link,
		queryParams: [
			['utm', urlParams?.utm],
			['utm_campaign', urlParams?.utmCampaign],
			['utm_medium', urlParams?.utmMedium],
			['utm_source', urlParams?.utmSource],
			['jobPipeline', urlParams?.jobPipeline],
		],
	})
}
