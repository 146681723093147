import { WithClass } from '@decadia/shared/types/extend-default-props'
import clsx from 'clsx'
import { HTMLMotionProps, motion } from 'framer-motion'
import { t } from 'i18next'
import { FC } from 'react'
import { Fingerprint } from '../../icons/fingerprint'
import { RweLogo } from '../../icons/rwe-logo'
import styles from './logo.module.css'

export const Logo: FC<WithClass<HTMLMotionProps<'div'>>> = ({ className, ...rest }) => {
	return (
		<motion.div className={clsx(styles.container, className)} {...rest}>
			<Fingerprint className={styles.fingerprint} />
			<RweLogo className={styles.logo} />
			<span className={clsx(styles.text, 'bold')}>{t('logo.name')}</span>
		</motion.div>
	)
}
