import { WithChildren, WithClass } from '@decadia/shared/types/extend-default-props'
import clsx from 'clsx'
import { HTMLMotionProps, Variants, motion } from 'framer-motion'
import { FC, useCallback, useEffect, useRef } from 'react'
import styles from './text-reveal.module.css'

export const TEXT_REVEAL_ANIMATION_DURATION = 2

type TextRevealProps = {
	sentences: { text: string; className?: string; trailingWhiteSpace?: boolean }[]
	autoSize?: boolean
	multiLine?: boolean
	minSize?: number
	maxSize?: number
	step?: number
	unit?: string
	duration?: number
}

export const TextReveal: FC<WithChildren<WithClass<TextRevealProps & HTMLMotionProps<'div'>>>> = ({
	sentences,
	children,
	className,
	autoSize = true,
	multiLine = true,
	maxSize = 130,
	minSize = 16,
	step = 1,
	unit = 'px',
	duration = TEXT_REVEAL_ANIMATION_DURATION,
	...rest
}) => {
	const { current: preparedSentences } = useRef(
		sentences.map(({ text, className, trailingWhiteSpace = true }, index, array) => ({
			text: trailingWhiteSpace && index + 1 < array.length ? text?.split('').concat([' ']) : text?.split(''),
			className,
		}))
	)

	const { current: letterCount } = useRef(
		preparedSentences.reduce((count, sentence) => {
			count += sentence.text.length
			return count
		}, 0)
	)

	const sentenceVariants: Variants = {
		hidden: {
			opacity: 0,
		},
		visible: {
			opacity: 1,
			transition: {
				staggerChildren: duration / letterCount,
			},
		},
	}

	const letter: Variants = {
		hidden: { opacity: 0 },
		visible: { opacity: 1 },
	}

	const resizeInProgress = useRef(false)
	const textRef = useRef<HTMLHeadingElement>(null)

	const resizeText = useCallback(() => {
		if (resizeInProgress.current === true) {
			return
		}

		if (!textRef.current) {
			return
		}

		const element = textRef.current
		const parent = element.parentElement

		if (!parent) {
			return
		}

		resizeInProgress.current = true
		let i = minSize
		let overflow = false

		const isOverflown = () => {
			if (multiLine) {
				return parent.scrollHeight > parent.clientHeight || parent.scrollWidth > parent.clientWidth
			}

			return parent.scrollWidth > parent.clientWidth
		}

		while (!overflow && i < maxSize) {
			element.style.fontSize = `${i}${unit}`
			overflow = isOverflown()

			if (!overflow) i += step
		}

		if (i - step <= minSize) {
			parent.style.minHeight = `${parent.scrollHeight}px`
		}

		element.style.fontSize = `${i - step}${unit}`
		resizeInProgress.current = false
	}, [maxSize, minSize, multiLine, step, unit])

	useEffect(() => {
		if (autoSize === false) {
			return
		}

		document.fonts.ready.then(() => {
			resizeText()
		})

		resizeText()

		window.addEventListener('resize', resizeText)

		return () => {
			window.removeEventListener('resize', resizeText)
		}
	}, [autoSize, resizeText])

	return (
		<motion.div
			className={clsx(
				styles.container,
				className,
				autoSize && styles['auto-size'],
				multiLine && styles['multi-line']
			)}
			exit={{ opacity: 0 }}
			{...rest}
		>
			<motion.h1
				initial={'hidden'}
				animate={'visible'}
				variants={sentenceVariants}
				className={clsx(styles.text, 'medium')}
				ref={textRef}
			>
				{preparedSentences.map(({ text, className }) =>
					text.map((char, index) => (
						<motion.span key={`${char}-${index}`} variants={letter} className={className}>
							{char}
						</motion.span>
					))
				)}
			</motion.h1>
			{children}
		</motion.div>
	)
}
