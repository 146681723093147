import { WithClass } from '@decadia/shared/types/extend-default-props'
import { FC } from 'react'

export const Fingerprint: FC<WithClass> = ({ className }) => (
	<svg className={className} xmlns="http://www.w3.org/2000/svg" width="71" height="113" fill="none">
		<path
			stroke="var(--color--white)"
			strokeLinecap="round"
			strokeMiterlimit="10"
			strokeWidth=".6"
			d="M1.7 95c0-4.1-.5-8.2-1.5-12m.6-10c4 9.2 5.6 20 3.6 30.5M2 67.1a53.2 53.2 0 0 1 5.2 39.2M3.6 62.6a54 54 0 0 1 6.4 45.5M5.5 58.9a54.9 54.9 0 0 1 7.3 50.6M7.6 55.6a55.7 55.7 0 0 1 8 55m-5.8-58c12.9 15 17.8 38.8 8.7 58.9M12 49.7a57.5 57.5 0 0 1 9.4 62.5m-6.6-64.7c15.1 15.9 21 43 9.6 65.2m-6.2-66.8a59.6 59.6 0 0 1 9.4 66.9m-5.5-68a60.7 60.7 0 0 1 8.8 67.8m-4.4-68.3a61.8 61.8 0 0 1 7.8 68m-3-67.9a63 63 0 0 1 6.6 67m-1.3-66a64.2 64.2 0 0 1 5.2 64.8m.5-63a65.3 65.3 0 0 1 3.7 61m2.2-58a66.5 66.5 0 0 1 2.5 54.9M54 54a67.9 67.9 0 0 1 2 45.5m4.2-38.8a69.4 69.4 0 0 1 1.5 29.8"
		/>
		<path
			fill="var(--color--light-green)"
			d="M65.3 19c-.4-.5-1-.8-1.7-.6-5.6 1.4-10.9 4.9-15.4 8.5-2.4 2-4.7 4.1-6.7 6.5-1.8 2-3.4 4.3-4.3 6.9-.2.7.7 1 1 .5 1.7-2.2 3.2-4.3 5-6.2a50.3 50.3 0 0 1 21.2-13.4c.7-.2 1-.8 1.1-1.4 0-.3 0-.6-.2-.8"
		/>
		<path
			fill="var(--color--light-green)"
			d="M63.3 15.2a36.8 36.8 0 0 0-27.5 19c-1.5 2.6-4.2 8.1-.9 10.5 4.1 3 8.2-2.3 10.4-5.1 1.4-1.8 2.7-4 4.4-5.6 1-1-.6-2.6-1.6-1.6-3.1 3.2-4.8 7.6-8.6 10.2-1.1.9-3.4 1.4-4-.3-.6-1.8.4-4 1.1-5.6 2-4.7 5.5-8.6 9.4-11.9 2.5-2 5.3-3.7 8.2-5.2 3-1.5 6.2-2.3 9.3-3.5.4-.2.4-.9-.2-.9Z"
		/>
		<path
			fill="var(--color--light-green)"
			d="M66.1 22.4c-1.2.2-2.3 1-3.4 1.7L59 26c-2.5 1.3-5.2 2.7-7.2 4.7-.4.4 0 1.1.5 1 2.5-1.1 4.9-2.6 7.3-3.9l3.7-2c1.2-.6 2.8-1.1 3.7-2.1.6-.7 0-1.6-.9-1.4M61 11.5c-3 0-6.4 1.1-9.3 2.3a44 44 0 0 0-18.9 15.4 39.3 39.3 0 0 0-4 11.2c-.5 3-.8 7 2.5 8.6 1 .5 2 .6 3.2.5 1.7 0 1.6-2.8 0-2.6-7.9 1-1.9-13.1-.4-16.2 2-4.3 5.5-7.4 9.3-10.1 2.7-2 5.7-3.5 8.7-4.9 3-1.2 6-1.8 9.2-2.4 1-.2.7-1.7-.2-1.8M67.4 26.6c-1.4 0-2.7 1.3-3.8 2l-4 2.6c-2.6 1.9-5 4-7.2 6.3-2 2.2-4 4.6-6.4 6.6a17 17 0 0 1-8.2 3.6c-.8.1-.8 1.4 0 1.6 2.8.7 5.8-.6 8.2-2.1a34 34 0 0 0 6.9-6.3 47.9 47.9 0 0 1 11.3-9.6l2-1.3c.8-.5 1.7-1 2.1-1.8.4-.7 0-1.6-1-1.6"
		/>
		<path
			fill="var(--color--light-green)"
			d="M57.7 38.4c-4 3.8-7.5 8.1-12.2 11.2-4.4 3-10.4 4.2-15.3 1.5-3.6-2-4.1-6.8-3.6-10.5.6-4.3 2.2-8.6 4.4-12.4a70.7 70.7 0 0 1 10.7-11.9A28.8 28.8 0 0 1 59 8.5c1.1 0 1.1-1.6 0-1.7a27 27 0 0 0-16.9 6.6 59.1 59.1 0 0 0-12 12.5c-2.4 3.5-4 7.7-5 11.8-1 4-1.5 8.6.6 12.4 3.2 5.9 12 6 17.5 3.7C49.8 51 54.4 45 59.4 40c1-1-.6-2.7-1.7-1.6M68.9 32.4c-1.4-.2-2.6.9-3.7 1.6l-2 1.3c-.6.5-.9 1-1.3 1.6-.4.5.3 1 .7.8l1.9-.8 1.8-1.1c1.1-.6 2.8-1.1 3.2-2.4.1-.4-.2-1-.6-1Z"
		/>
		<path
			fill="var(--color--light-green)"
			d="M54 3.2c-1.8-.1-3.8.6-5.4 1.2a41.2 41.2 0 0 0-14 9.4c-5.3 5.4-9.3 12.1-12.2 19-.5 1.5 1.8 2 2.3.7A57.6 57.6 0 0 1 36 15.7a40.7 40.7 0 0 1 13-9.2c1.8-.7 3.7-1 5.3-2 .6-.4.3-1.2-.3-1.3M68 37.7c-2.5 1.2-5.1 3-7.4 4.7-2.4 1.7-4.4 3.9-6.6 5.8a32.7 32.7 0 0 1-14.8 8.3c-4.7.9-11 .3-13.9-4C23 49 23 44.1 23 40c0-.4-.5-.5-.6-.1a17.4 17.4 0 0 0 1 13.4c2.9 5 8.8 6.7 14.3 6 6.1-.8 11.8-4 16.4-7.8 2.5-2 4.6-4.3 7-6.4 1.4-1.1 2.9-2.1 4.4-3 1.3-.9 2.8-1.6 4-2.6 1.1-.9-.2-2.2-1.3-1.8"
		/>
		<path
			fill="var(--color--light-green)"
			d="M69.7 42a13 13 0 0 0-8 4.3c-2.3 2.3-4.4 4.8-7 6.8-4.7 3.6-10 6.8-16 7.6-5 .6-10.6-.2-14.1-4A22 22 0 0 1 19.8 43c-.5-9.1 3-18.3 8.2-25.7C33 10.3 40.2 4 49 2c1.1-.2.6-2-.5-1.7-10 2-18 9.6-23.4 17.9a42.1 42.1 0 0 0-7 29.5c1 4.6 3 9.6 6.9 12.4a20 20 0 0 0 14.5 3.2c5.9-.8 10.9-3.8 15.6-7.1 2.7-1.9 5-4 7.2-6.3 2-2.1 4.4-4.8 7.5-5 1.7-.2 1.8-3 0-2.8"
		/>
		<path
			fill="var(--color--light-green)"
			d="M39.3.2c-1.1-.6-2.6.4-3.6 1-1.4.6-2.7 1.6-3.9 2.6a39 39 0 0 0-6.6 7.6 92.4 92.4 0 0 0-7.6 13c-.6 1.3-1.4 2.6-1.8 4-.3 1.1 1.2 1.6 1.8.8.7-1 1.3-2.3 1.8-3.4l2-4.1c1.5-3 3-5.8 4.9-8.5a41 41 0 0 1 6.2-7.4c1-1 2.3-2 3.6-2.7 1-.6 3-1 3.4-2.2.1-.2.1-.6-.2-.7M40.5 65.4c-2.5.5-4.7.6-7.2.5a16 16 0 0 1-6.5-2.3c-2-1.2-4-2.6-5.7-4.1-.4-.3-3.5-3.6-3.9-2.4-.2.8.6 1.4 1.1 2a58 58 0 0 0 2.7 2.5c1.8 1.6 3.7 3 5.8 4.1a20 20 0 0 0 14.3 2c1.5-.5.9-2.7-.6-2.3M67.9 49.4c-4.6 1.5-8 5.1-11.4 8.3a43.6 43.6 0 0 1-5.6 4.5c-1 .6-2 1.1-3.1 1.5-1.2.4-2.5.4-3.6 1-.5.1-.4 1 0 1.2 2 1 4.8-.1 6.6-1 2.2-1 4.3-2.6 6-4.3 3.4-3.5 6.8-7.8 11.6-9 1.4-.4.8-2.7-.5-2.2"
		/>
		<path
			fill="var(--color--light-green)"
			d="M67.6 55.5c-1.3-.1-2.9.7-4 1.3-1.4.7-2.6 1.7-3.7 2.8-2.2 2-4.1 4.5-6.6 6.3-3.1 2.2-7 3-10.8 3.5a34.7 34.7 0 0 1-9-.2l-3-.6c-1.3-.1-1.7 1.6-.7 2.1 1.7 1 4 1 5.8 1.2 2.2.1 4.3 0 6.5-.2 3.7-.4 8-1.2 11.2-3.3 2.6-1.7 4.7-4.2 6.9-6.3 1.1-1.1 2.3-2.2 3.6-3 1.4-.9 3-1 4.3-1.8 1-.5.4-1.7-.5-1.8M26.9 68c-2-.3-3.4-1.2-5-2.4-.7-.6-1.5.6-.9 1.2 1.5 1.3 3.3 2.8 5.3 3.2 1.4.3 2-1.8.6-2"
		/>
		<path
			fill="var(--color--light-green)"
			d="M51.4 71.3c-2 .4-4 1.4-6 2l-3 1c-1.4.4-2.9.2-4.2.2a20 20 0 0 1-6.7-1.3l-3.1-1.3c-1-.3-2.2-1.2-3.1-.8-.2.1-.4.3-.3.5.4 1 1.5 1.2 2.4 1.6l3.3 1.5c2.2.9 4.6 1.6 7 1.8 1.4.1 2.5-.2 3.8-.3l3.6-.7c2.4-.7 4.9-1.4 7-2.4 1.1-.5.3-2-.7-1.8M63.9 63.5c-3.7 1-6.4 4.4-9.2 6.8-.5.5.2 1.5.9 1 1.5-1 2.8-2.2 4.2-3.4 1.4-1.2 3-2 4.7-3 .7-.4.2-1.6-.6-1.4M59.3 72c-.1-.3-.4-.5-.8-.4-.6.2-.9.7-1.3 1.2-.6.6-1.4 1-2.1 1.4A36.3 36.3 0 0 1 38.3 78c-1.4 0-1.4 2 0 2.1a37 37 0 0 0 18-4.6c1-.6 3.4-2 3-3.4"
		/>
	</svg>
)
