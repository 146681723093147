import { WithChildren, WithClass, WithStyle } from '@decadia/shared/types/extend-default-props'
import { useKeyboardEvent } from '@react-hookz/web'
import clsx from 'clsx'
import { AnimationLifecycles, MotionProps, motion, useAnimation } from 'framer-motion'
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { mergeRefs } from 'react-merge-refs'
import styles from './modal.module.css'
import { TModalProps, TModalRefActions } from './modal.types'

const CONTAINER_ANIMATION_DURATION = 0.35
const CONTAINER_ANIMATION_POSITION_DELAY = 0.1
const CONTENT_ANIMATION_DURATION = 0.2

const CONTAINER_ANIMATION: MotionProps['variants'] = {
	initial: {
		opacity: 0,
		y: -20,
		transition: {
			y: { duration: CONTAINER_ANIMATION_DURATION, delay: CONTENT_ANIMATION_DURATION },
			default: {
				duration: CONTAINER_ANIMATION_DURATION - CONTAINER_ANIMATION_POSITION_DELAY,
				delay: CONTENT_ANIMATION_DURATION,
			},
		},
	},
	get hidden() {
		return this.initial
	},
	show: {
		opacity: 1,
		y: 0,
		transition: {
			y: { duration: CONTAINER_ANIMATION_DURATION },
			default: {
				duration: CONTAINER_ANIMATION_DURATION - CONTAINER_ANIMATION_POSITION_DELAY,
				delay: CONTAINER_ANIMATION_POSITION_DELAY,
			},
		},
	},
}

export const Modal = forwardRef<TModalRefActions | undefined, WithClass<WithChildren<WithStyle<TModalProps>>>>(
	(props, ref) => {
		const { onModalClose, onModalOpen, className, children, dialogRef: injectedDialogRef, style } = props

		const [modalVisible, setModalVisibility] = useState<boolean>(true)

		const openActionCallback = useRef<(() => void) | undefined>()
		const closeActionCallback = useRef<(() => void) | undefined>()

		const dialogRef = useRef<HTMLDialogElement>(null)

		const animationControls = useAnimation()

		const openModalEvent = useCallback(() => {
			dialogRef.current?.scrollTo(0, 0)
			animationControls.start('show')
		}, [animationControls])

		const closeModalEvent = useCallback(() => {
			animationControls.start('hidden')
		}, [animationControls])

		useImperativeHandle(ref, () => ({
			open(callback) {
				if (!modalVisible) {
					if (callback && typeof callback === 'function') {
						openActionCallback.current = callback
					}
					setModalVisibility(true)
				}
			},
			close(callback) {
				if (modalVisible) {
					if (callback && typeof callback === 'function') {
						closeActionCallback.current = callback
					}
					setModalVisibility(false)
				}
			},
			toggle() {
				if (modalVisible) {
					setModalVisibility(false)
				} else {
					setModalVisibility(true)
				}
			},
		}))

		// useEffect(() => {
		// 	if (showInitially === true) {
		// 		setTimeout(() => {
		// 			setModalVisibility(true)
		// 		}, 500)
		// 	}
		// }, [showInitially])

		useEffect(() => {
			if (modalVisible === true) {
				dialogRef.current?.showModal()

				openModalEvent()
				return
			}

			if (modalVisible === false) {
				closeModalEvent()
			}
		}, [closeModalEvent, modalVisible, openModalEvent])

		const closeModal = (e: unknown) => {
			/**
			 * prevents the default close behaviour of the dialog element
			 * which instantly removes the dialog element and
			 * makes animations impossible
			 */
			if (e instanceof KeyboardEvent && modalVisible === true) {
				e.preventDefault()
			}

			if (modalVisible === true) {
				setModalVisibility(false)
			}
		}

		useKeyboardEvent('Escape', closeModal)

		const onShowComplete = () => {
			onModalOpen && onModalOpen()
			openActionCallback.current && openActionCallback.current()
		}

		const onHiddenComplete = () => {
			dialogRef.current?.close()

			onModalClose?.()

			closeActionCallback.current?.()
		}

		const handleOnAnimationComplete: AnimationLifecycles['onAnimationComplete'] = (variant) => {
			switch (variant) {
				case 'hidden':
					onHiddenComplete()
					break

				case 'show':
					onShowComplete()
					break
			}
		}

		return (
			<motion.dialog
				className={clsx(styles.container, className)}
				initial={'initial'}
				animate={animationControls}
				exit="hidden"
				variants={CONTAINER_ANIMATION}
				ref={mergeRefs([dialogRef, injectedDialogRef || null])}
				onAnimationComplete={handleOnAnimationComplete}
				aria-hidden={modalVisible === true ? undefined : true}
				style={style}
			>
				{children}
			</motion.dialog>
		)
	}
)
