import { addUrlParametersToUrl } from '../helper/add-url-parameters-to-url'
import { Result } from '../types'
import { useStore } from './use-store'

export const useJobUrl = () => {
	const { links, urlParams, language } = useStore(({ links, urlParams, language }) => ({
		links,
		urlParams,
		language,
	}))

	const getResultLocale = (locale: Result['locale'] = []) => {
		if (locale.length > 1) {
			if (language === 'de' && locale.includes('de_DE')) {
				return 'de_DE'
			}

			if (locale.includes('en_GB')) {
				return 'en_GB'
			}
		}

		return locale[0]
	}

	const getJobUrl = ({ ad_code, locale }: Result) => {
		const resultLocale = getResultLocale(locale)
		const jobDetailLink = resultLocale === 'de_DE' ? links?.jobdetailDe : links?.jobdetailEn

		const link = `${jobDetailLink}?id=${ad_code}-${resultLocale}`

		return addUrlParametersToUrl({
			link,
			urlParams,
		})
	}

	return { getJobUrl, getResultLocale }
}
